var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"pl-3",attrs:{"md":"12"}},[_c('div',{},[_c('b-table',{staticClass:"bg-white",staticStyle:{"max-height":"calc(98vh - 250px) !important"},attrs:{"striped":"","hover":"","outlined":"","items":_vm.availedItcData,"fields":_vm.availedItcFields,"show-empty":"","bordered":"","sticky-header":""},scopedSlots:_vm._u([{key:"head(sub_heading)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.tabType === 'TABLE_7' ? '7' : _vm.tabType === 'TABLE_8' ? '8' : _vm.tabType === 'TABLE_9' ? '9' : _vm.tabType === 'TABLE_10' ? '10' : _vm.tabType === 'TABLE_14' ? '14' : _vm.tabType === 'TABLE_15' ? '15' : _vm.tabType === 'TABLE_16' ? '16' : ''))])]},proxy:true},{key:"cell(sub_heading)",fn:function(data){return [_c('span',{staticStyle:{"min-width":"20rem"}},[_vm._v(_vm._s(data.item.sub_heading))])]}},{key:"head(sub_type_name)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.tabType === 'TABLE_15' || _vm.tabType === 'TABLE_16' ? 'Details' : 'Description'))])]},proxy:true},{key:"cell(sub_type_name)",fn:function(data){return [_c('span',{staticStyle:{"min-width":"20rem"}},[_vm._v(_vm._s(data.item.sub_type_name))])]}},{key:"cell(taxable_value)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",attrs:{"title":data.item.taxable_value,"disabled":!_vm.editMode},model:{value:(data.item.taxable_value),callback:function ($$v) {_vm.$set(data.item, "taxable_value", $$v)},expression:"data.item.taxable_value"}})]}},{key:"cell(central_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",attrs:{"title":data.item.central_tax,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_9' &&
                    (data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G'))},model:{value:(data.item.central_tax),callback:function ($$v) {_vm.$set(data.item, "central_tax", $$v)},expression:"data.item.central_tax"}})]}},{key:"cell(state_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",attrs:{"title":data.item.state_tax,"disabled":!_vm.editMode},model:{value:(data.item.state_tax),callback:function ($$v) {_vm.$set(data.item, "state_tax", $$v)},expression:"data.item.state_tax"}})]}},{key:"cell(integrated_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                _vm.tabType === 'TABLE_7' &&
                (data.item.sub_heading == 'F' || data.item.sub_heading == 'G')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.integrated_tax,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_7' &&
                    (data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G'))},model:{value:(data.item.integrated_tax),callback:function ($$v) {_vm.$set(data.item, "integrated_tax", $$v)},expression:"data.item.integrated_tax"}})]}},{key:"cell(cess)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                _vm.tabType === 'TABLE_7' &&
                (data.item.sub_heading == 'F' || data.item.sub_heading == 'G')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.cess,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_7' &&
                    (data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G'))},model:{value:(data.item.cess),callback:function ($$v) {_vm.$set(data.item, "cess", $$v)},expression:"data.item.cess"}})]}},{key:"cell(Taxpayable)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",attrs:{"title":data.item.Taxpayable,"disabled":!_vm.editMode},model:{value:(data.item.Taxpayable),callback:function ($$v) {_vm.$set(data.item, "Taxpayable", $$v)},expression:"data.item.Taxpayable"}})]}},{key:"cell(Paid_through_cash)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",attrs:{"title":data.item.Paid_through_cash,"disabled":!_vm.editMode},model:{value:(data.item.Paid_through_cash),callback:function ($$v) {_vm.$set(data.item, "Paid_through_cash", $$v)},expression:"data.item.Paid_through_cash"}})]}},{key:"cell(Tax_Paid_Itc_Iamt)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                _vm.tabType === 'TABLE_9' &&
                (data.item.sub_heading == 'D' ||
                  data.item.sub_heading == 'E' ||
                  data.item.sub_heading == 'F' ||
                  data.item.sub_heading == 'G' ||
                  data.item.sub_heading == 'H')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.Tax_Paid_Itc_Iamt,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_9' &&
                    (data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H'))},model:{value:(data.item.Tax_Paid_Itc_Iamt),callback:function ($$v) {_vm.$set(data.item, "Tax_Paid_Itc_Iamt", $$v)},expression:"data.item.Tax_Paid_Itc_Iamt"}})]}},{key:"cell(Tax_Paid_Itc_Camt)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                _vm.tabType === 'TABLE_9' &&
                (data.item.sub_heading == 'C' ||
                  data.item.sub_heading == 'D' ||
                  data.item.sub_heading == 'E' ||
                  data.item.sub_heading == 'F' ||
                  data.item.sub_heading == 'G' ||
                  data.item.sub_heading == 'H')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.Tax_Paid_Itc_Camt,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_9' &&
                    (data.item.sub_heading == 'C' ||
                      data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H'))},model:{value:(data.item.Tax_Paid_Itc_Camt),callback:function ($$v) {_vm.$set(data.item, "Tax_Paid_Itc_Camt", $$v)},expression:"data.item.Tax_Paid_Itc_Camt"}})]}},{key:"cell(Tax_Paid_Itc_Samt)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                _vm.tabType === 'TABLE_9' &&
                (data.item.sub_heading == 'B' ||
                  data.item.sub_heading == 'D' ||
                  data.item.sub_heading == 'E' ||
                  data.item.sub_heading == 'F' ||
                  data.item.sub_heading == 'G' ||
                  data.item.sub_heading == 'H')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.Tax_Paid_Itc_Samt,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_9' &&
                    (data.item.sub_heading == 'B' ||
                      data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H'))},model:{value:(data.item.Tax_Paid_Itc_Samt),callback:function ($$v) {_vm.$set(data.item, "Tax_Paid_Itc_Samt", $$v)},expression:"data.item.Tax_Paid_Itc_Samt"}})]}},{key:"cell(Tax_Paid_Itc_Csamt)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                _vm.tabType === 'TABLE_9' &&
                (data.item.sub_heading == 'A' ||
                  data.item.sub_heading == 'B' ||
                  data.item.sub_heading == 'C' ||
                  data.item.sub_heading == 'E' ||
                  data.item.sub_heading == 'F' ||
                  data.item.sub_heading == 'G' ||
                  data.item.sub_heading == 'H')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.Tax_Paid_Itc_Csamt,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_9' &&
                    (data.item.sub_heading == 'A' ||
                      data.item.sub_heading == 'B' ||
                      data.item.sub_heading == 'C' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H'))},model:{value:(data.item.Tax_Paid_Itc_Csamt),callback:function ($$v) {_vm.$set(data.item, "Tax_Paid_Itc_Csamt", $$v)},expression:"data.item.Tax_Paid_Itc_Csamt"}})]}},{key:"cell(Tax_paid)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",attrs:{"title":data.item.Tax_paid,"disabled":!_vm.editMode},model:{value:(data.item.Tax_paid),callback:function ($$v) {_vm.$set(data.item, "Tax_paid", $$v)},expression:"data.item.Tax_paid"}})]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  props: ['tabDetail', 'searchValue'],
  components: {},
  data() {
    return {
      hsnOutwardInwardData: [],
      hsnOutwardInwardFields: [
        {
          key: 'hsn_code',
          label: 'HSN Code',
          class: 'text-center'
        },
        {
          key: 'description',
          label: 'Description',
          class: 'text-left'
        },
        {
          key: 'uqc',
          label: 'UQC',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Total Quantity',
          class: 'text-center'
        },
        {
          key: 'taxable_value',
          label: 'Taxable Value (₹)',
          class: 'text-center'
        },
        {
          key: 'isconcesstional',
          label: 'Is supply eligible for concessional rate of tax',
          class: 'text-center'
        },
        {
          key: 'rate',
          label: 'Rate of Tax(%)',
          class: 'text-center'
        },
        {
          key: 'igst',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cgst',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'sgst',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      editMode: false,
      initialTaxOutwardData: [],
      tabType: null
    };
  },
  mounted() {
    if (this.tabDetail) {
      this.tabType = this.tabDetail.type;
      //   this.hsnOutwardInwardFields =
      //     this.tabType === 'TABLE_18'
      //       ? this.tab18Fields
      //       : this.hsnOutwardInwardFields;
    }
    this.getGstr9TabsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.editGstrNineTabData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getGstr9TabsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrNine/getGstrNineTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.hsnOutwardInwardData = response.data.data.detail;
            // this.initialTaxOutwardData = JSON.parse(
            //   JSON.stringify(response.data.data.detail)
            // );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};

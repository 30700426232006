var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"pl-3",attrs:{"md":"12"}},[_c('div',{},[_c('b-table',{staticClass:"bg-white",staticStyle:{"max-height":"calc(98vh - 250px) !important"},attrs:{"striped":"","hover":"","outlined":"","items":_vm.outInTaxPayableData,"fields":_vm.outInTaxPayableFields,"show-empty":"","bordered":"","sticky-header":""},scopedSlots:_vm._u([{key:"head(sub_heading)",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.tabType === 'TABLE_4' ? '4' : '5'))])]},proxy:true},{key:"head(sub_type_name)",fn:function(){return [_c('span',[_vm._v("Nature of Supplies")])]},proxy:true},{key:"cell(taxable_value)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",attrs:{"title":data.item.taxable_value,"disabled":!_vm.editMode},model:{value:(data.item.taxable_value),callback:function ($$v) {_vm.$set(data.item, "taxable_value", $$v)},expression:"data.item.taxable_value"}})]}},{key:"cell(central_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],class:_vm.searchValue.filed_flag &&
                ((_vm.tabType === 'TABLE_4' &&
                  (data.item.sub_heading == 'C' ||
                    data.item.sub_heading == 'D')) ||
                  (_vm.tabType === 'TABLE_5' &&
                    (data.item.sub_heading == 'A' ||
                      data.item.sub_heading == 'B' ||
                      data.item.sub_heading == 'C' ||
                      data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H' ||
                      data.item.sub_heading == 'I' ||
                      data.item.sub_heading == 'J' ||
                      data.item.sub_heading == 'K' ||
                      data.item.sub_heading == 'L' ||
                      data.item.sub_heading == 'M')))
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.central_tax,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_4' &&
                    (data.item.sub_heading === 'C' ||
                      data.item.sub_heading === 'D')) ||
                  (_vm.tabType === 'TABLE_5' &&
                    (data.item.sub_heading == 'A' ||
                      data.item.sub_heading == 'B' ||
                      data.item.sub_heading == 'C' ||
                      data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H' ||
                      data.item.sub_heading == 'I' ||
                      data.item.sub_heading == 'J' ||
                      data.item.sub_heading == 'K' ||
                      data.item.sub_heading == 'L' ||
                      data.item.sub_heading == 'M'))},model:{value:(data.item.central_tax),callback:function ($$v) {_vm.$set(data.item, "central_tax", $$v)},expression:"data.item.central_tax"}})]}},{key:"cell(state_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                ((_vm.tabType === 'TABLE_4' &&
                  (data.item.sub_heading == 'C' ||
                    data.item.sub_heading == 'D')) ||
                  (_vm.tabType === 'TABLE_5' &&
                    (data.item.sub_heading == 'A' ||
                      data.item.sub_heading == 'B' ||
                      data.item.sub_heading == 'C' ||
                      data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H' ||
                      data.item.sub_heading == 'I' ||
                      data.item.sub_heading == 'J' ||
                      data.item.sub_heading == 'K' ||
                      data.item.sub_heading == 'L' ||
                      data.item.sub_heading == 'M')))
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.state_tax,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_4' &&
                    (data.item.sub_heading === 'C' ||
                      data.item.sub_heading === 'D')) ||
                  (_vm.tabType === 'TABLE_5' &&
                    (data.item.sub_heading == 'A' ||
                      data.item.sub_heading == 'B' ||
                      data.item.sub_heading == 'C' ||
                      data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H' ||
                      data.item.sub_heading == 'I' ||
                      data.item.sub_heading == 'J' ||
                      data.item.sub_heading == 'K' ||
                      data.item.sub_heading == 'L' ||
                      data.item.sub_heading == 'M'))},model:{value:(data.item.state_tax),callback:function ($$v) {_vm.$set(data.item, "state_tax", $$v)},expression:"data.item.state_tax"}})]}},{key:"cell(integrated_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                _vm.tabType === 'TABLE_5' &&
                (data.item.sub_heading == 'A' ||
                  data.item.sub_heading == 'B' ||
                  data.item.sub_heading == 'C' ||
                  data.item.sub_heading == 'D' ||
                  data.item.sub_heading == 'E' ||
                  data.item.sub_heading == 'F' ||
                  data.item.sub_heading == 'G' ||
                  data.item.sub_heading == 'H' ||
                  data.item.sub_heading == 'I' ||
                  data.item.sub_heading == 'J' ||
                  data.item.sub_heading == 'K' ||
                  data.item.sub_heading == 'L' ||
                  data.item.sub_heading == 'M')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.integrated_tax,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_5' &&
                    (data.item.sub_heading == 'A' ||
                      data.item.sub_heading == 'B' ||
                      data.item.sub_heading == 'C' ||
                      data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H' ||
                      data.item.sub_heading == 'I' ||
                      data.item.sub_heading == 'J' ||
                      data.item.sub_heading == 'K' ||
                      data.item.sub_heading == 'L' ||
                      data.item.sub_heading == 'M'))},model:{value:(data.item.integrated_tax),callback:function ($$v) {_vm.$set(data.item, "integrated_tax", $$v)},expression:"data.item.integrated_tax"}})]}},{key:"cell(cess)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                _vm.tabType === 'TABLE_5' &&
                (data.item.sub_heading == 'A' ||
                  data.item.sub_heading == 'B' ||
                  data.item.sub_heading == 'C' ||
                  data.item.sub_heading == 'D' ||
                  data.item.sub_heading == 'E' ||
                  data.item.sub_heading == 'F' ||
                  data.item.sub_heading == 'G' ||
                  data.item.sub_heading == 'H' ||
                  data.item.sub_heading == 'I' ||
                  data.item.sub_heading == 'J' ||
                  data.item.sub_heading == 'K' ||
                  data.item.sub_heading == 'L' ||
                  data.item.sub_heading == 'M')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.cess,"disabled":!_vm.editMode ||
                  (_vm.tabType === 'TABLE_5' &&
                    (data.item.sub_heading == 'A' ||
                      data.item.sub_heading == 'B' ||
                      data.item.sub_heading == 'C' ||
                      data.item.sub_heading == 'D' ||
                      data.item.sub_heading == 'E' ||
                      data.item.sub_heading == 'F' ||
                      data.item.sub_heading == 'G' ||
                      data.item.sub_heading == 'H' ||
                      data.item.sub_heading == 'I' ||
                      data.item.sub_heading == 'J' ||
                      data.item.sub_heading == 'K' ||
                      data.item.sub_heading == 'L' ||
                      data.item.sub_heading == 'M'))},model:{value:(data.item.cess),callback:function ($$v) {_vm.$set(data.item, "cess", $$v)},expression:"data.item.cess"}})]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }